/** @jsx jsx */
import { jsx, Alert } from "theme-ui";
import { Spinner as SpinSmall } from "office-ui-fabric-react";
import PropTypes from "prop-types";
import { doFormatTitle } from "@heartfulnessinstitute/react-hfn-forms/dist/formatting";
import { useSrcmApi } from "../../fetch-srcm-api";

function SearchModel({
  model,
  methodParams,
  onlyFirstRender,
  allResultsRender,
  client,
}) {
  const apiParams = { api: `/api/v2/${model}/`, methodParams, client };

  const { isFetching, data, errorMessage } = useSrcmApi(
    `${model}${JSON.stringify(methodParams)}`,
    apiParams
  );
  if (isFetching) {
    return <SpinSmall />;
  }
  if (errorMessage) {
    return <Alert>{errorMessage}</Alert>;
  }
  if (data === null) {
    return <SpinSmall />;
  }

  if (allResultsRender) {
    return <span>{allResultsRender(data.results)}</span>;
  }
  if (onlyFirstRender) {
    return <span>{onlyFirstRender(data.results[0])}</span>;
  }
  return <span>{doFormatTitle(data.results[0].name)}</span>;
}

SearchModel.propTypes = {
  model: PropTypes.string.isRequired,
  methodParams: PropTypes.shape({}).isRequired,
  onlyFirstRender: PropTypes.func,
  allResultsRender: PropTypes.func,
  client: PropTypes.string.isRequired,
};
SearchModel.defaultProps = {
  onlyFirstRender: null,
  allResultsRender: null,
};

export default SearchModel;
